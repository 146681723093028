import UnderDevelopment from "./components/UnderDevelopment";

const App = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200">
      <UnderDevelopment />
    </div>
  );
};

export default App;
