import React, { useState, useEffect } from "react";
import { ReactComponent as SunIcon } from "../assets/Sun.svg";
import { ReactComponent as MoonIcon } from "../assets/Moon.svg";

const UnderDevelopment: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative bg-cover bg-center" style={{backgroundImage: 'url("/images/Filipino cuisines photo.jpg")'}}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
        <img
          src={darkMode ? "/images/Sarap eats logo black.png" : "/images/Sarap eats logo white.png"}
          alt="SarapEats Logo"
          className="w-48 sm:w-64 h-auto mb-8"
        />
        <h1 className="text-3xl sm:text-4xl font-bold text-orange-500 mb-4 text-center">
          We're cooking up something delicious!
        </h1>
        <p className="text-lg sm:text-xl mb-8 max-w-2xl text-center text-white">
          SarapEats is currently under development. We'll be serving up the best
          restaurant recommendations soon!
        </p>
        
      </div>
      <button
        onClick={toggleDarkMode}
        className="absolute bottom-4 right-4 p-2 bg-white dark:bg-gray-700 rounded-full shadow-md z-20"
      >
        {darkMode ? (
          <SunIcon className="w-6 h-6 text-yellow-500" />
        ) : (
          <MoonIcon className="w-6 h-6 text-gray-700" />
        )}
      </button>
    </div>
  );
};

export default UnderDevelopment;